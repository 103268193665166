import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/Layout"


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      
      <Layout title={post.frontmatter.title.concat(' | ').concat(siteTitle)} description={post.frontmatter.description}>
        <section className="section margin-top-navbar">
          <div className="container">
            <article className="narrow-article">
              <header>
                <Img fluid={post.fields.headerImage.childImageSharp.fluid} alt={post.frontmatter.title}/>
                <h1 className="title is-2 has-text-weight-bold">
                  {post.frontmatter.title}
                </h1>
                <p
                  style={{
                    display: `block`,
                    marginBottom: `1rem`,
                  }}
                >
                  {post.frontmatter.date}
                </p>
                <hr
                style={{
                  marginBottom: `1rem`,
                }}
              />
              </header>
              <MDXRenderer>{post.body}</MDXRenderer>
              
            </article>
          </div>
        </section>


      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
        headerImage {
          childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        description
      }
    }
  }
`
